@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{ margin: 0; padding: 0; }
body, body p { font-family: 'Montserrat', sans-serif; color: #6C7890; }
p { font-size: 14px; line-height: 21px; color: #6C7890;  }
a { line-height: initial; }
a:hover, a:active  { text-decoration: none!important; }
ul, ol { list-style-type: none; margin: 0; padding: 0;  }
img{width: auto; margin: auto; max-width: 100%; }

figure { margin: 0; }

/*Header start*/

.header {padding: 15px 0 0;}
.header .row {flex-direction: row;align-items: center;}
.header a.logo { display: inline-block; }
.header a.logo img {max-height: 90px;object-fit: cover;}
.header ul {text-align: right;}
.header ul li {display: inline-block;vertical-align: middle;margin: 0 0 0 50px;}
.header ul li:first-child { margin: 0;  }
.header ul li a {padding: 0px 5px 5px;display: block;color: #081D43;font-weight: 500;text-transform: capitalize;transition: 0.4s !important;}
.header ul li a:hover, .header ul li a.active {font-weight: 600;transition: 0.4s !important;}
.header ul li:last-child a {padding: 12px 20px;background: #0E355B;color: #fff;border-radius: 5px;line-height: 14px;}

/*Header end */

/*Home banner */

.banner { padding: 2% 0 0; }
.banner .row {align-items: center;}
.banner .box h4 {font-size: 26px;margin: 0 0 30px;line-height: 33px;}
.banner .box p {line-height: 29px;margin: 0 0 40px;}
.banner .box ul li, .about-banner .box ul li {display: inline-block;vertical-align: middle;width: 31%;text-align: center;margin: 0 10px 0 0;}
.banner .box ul li a, 
.about-banner .box ul li a, 
.banner .box ul li button {display: block;background: #0E355B;padding: 12px 10px;border-radius: 5px;color: #fff;font-size: 13px; width: 100%; }
.banner .box ul li a img, .about-banner .box ul li a img {display: inline-block;width: 10%;margin: 0 15px 0 0;vertical-align: middle;}


/*Home banner End*/

.sevice-sec { padding: 20px 0 50px; background-position-x: left -20%;
    background-position-y: bottom -30px;
    background-size: 30% 95%;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;}
.mn-h4 { color: #081D43;text-align: center;margin: 0 0 40px;font-weight: 600; }
.mn-h4 small {display: block;color: #6C7890;font-size: 14px;margin: 0 0 15px;text-transform: uppercase;font-weight: 500;}
.sevice-sec .serv-box {text-align: center;box-shadow: 0px 0px 20px -5px rgb(14 53 91 / 50%);padding: 50px;border-radius: 5px;}
.sevice-sec .serv-box span {display: block;margin: 0 0 30px;}
.sevice-sec .serv-box span img { height: 50px; }
.sevice-sec .serv-box h5 {margin: 0 0 30px;color: #0E355B;font-size: 18px;font-weight: 600;}
.sevice-sec .serv-box p {margin: 0;line-height: 27px;}
.sevice-sec .serv-box2 {background: #0E355B;color: #fff;}
.sevice-sec .serv-box2 h5 {color: #fff;}
.sevice-sec .serv-box2 p {color: #fff;}

.home-techno {background-color: #0E355B;padding: 6% 0;text-align: center;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;}
.home-techno h4 {width: 80%;margin: 0 auto 30px;color: #fff;line-height: 31px;word-spacing: 2px;}
.home-techno p {color: #fff;line-height: 27px;font-weight: 300;font-size: 14px;margin: 0 0 30px;}
.btn {display: inline-block;padding: 10px 30px;background: #6C7890;color: #fff;vertical-align: middle;text-transform: inherit;}

.we-ofer {text-align: center;padding: 70px 0;}
.we-ofer figure {text-align: center;padding: 40px;}
.we-ofer figure span {display: inline-block;margin: 0 0 30px;}

/* About Banner */
.about-banner .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.solution .row, .wcu-sec .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ah_services .row {
    display: flex;
    justify-content: center;
}

.ah_h4 {font-size: 28px;color: #081D43;font-weight: 600;}
.ttu{ text-transform: uppercase;}
.about-banner { position: relative; margin-top: 100px; margin-bottom: 50px; }
.about-banner .box p {margin: 0 0 20px !important;}
.about-banner .box h4 {margin-bottom: 18px;}

footer.footer {background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;}
.footer .row {align-items: center;}
.footer .foot-top {padding: 20px;text-align: center;border-bottom: 1px solid #DDE1E9;}
.footer .foot-top h4 {margin: 0;color: #fff;font-size: 21px;font-weight: 400;letter-spacing: 1px;}
.footer .foot-top a.btn {padding: 10px 50px;}
.footer .foot-mid {padding: 30px 0;}
.footer .foot-mid figure {padding: 20px 0 0;}
.footer .foot-mid .row { align-items: flex-start; }
.footer .foot-mid figure a.foot-logo {display: inline-block;}
.footer .foot-mid figure a.foot-logo img {max-height: 110px;object-fit: cover;}
.footer .foot-mid h4 {color: #fff;font-size: 16px;margin: 0 0 30px;font-weight: 500;letter-spacing: 0.5px;}

.footer .foot-mid ul li {margin: 0 0 15px;}
.footer .foot-mid ul li a {display: block;color: #fff;font-size: 13px;font-weight: 300;}
.footer .copyright {padding: 20px 0;border-top: 1px solid #DDE1E9;}
.footer .copyright p {margin: 0;color: #fff;font-weight: 200;font-size: 13px;}
.footer .copyright p span {display: inline-block;line-height: initial;color: #6C7890;margin: 0 5px 0 0;}

/*Modal Css*/
.form-outline .form-control~.form-label {
    position: absolute;
    top: 0;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: .75rem;
    padding-top: .37rem;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    color: rgba(0,0,0,.6);
    margin-bottom: 0;
}
.form-outline .form-control:focus~.form-label {
    color: #1266f1;
}

.form-outline .form-control.active~.form-label, .form-outline .form-control:focus~.form-label {
    -webkit-transform: translateY(-1rem) translateY(.1rem) scale(.8);
    transform: translateY(-1rem) translateY(.1rem) scale(.8);
}

.form-outline .form-control:focus + label {
    left: 12px !important;
    top: 5px;
    background-color: white;
    line-height: 1;
}
.form-outline .form-control.active~.form-label, .form-outline .form-control:focus~.form-label {
    -webkit-transform: translateY(-1rem) translateY(.1rem) scale(.8);
    transform: translateY(-1rem) translateY(.1rem) scale(.8);
}
.btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
}
.customodal .modal-content {padding: 37px;border-radius: 15px;}
.customodal h4 {text-align: center;margin: 0 0 40px;color: #081D43;font-weight: 600;}
.customodal h4 span {display: block;text-transform: uppercase;font-size: 14px;color: #6C7890;margin: 0 0 10px;font-weight: 500;}
.customodal .form-outline {border: 0 !important;outline: 0 !important;margin: 0 0 20px;box-shadow: 0px 0px 10px 3px #00000029;border-radius: 5px;display: flex;align-items: center;}
.customodal .form-outline input {border: 0 !important;outline: 0 !important;height: 40px;}
.customodal .form-outline textarea {height: 160px; border: none;}
.form-outline .form-control~.form-label {
    font-size: 13px;
    top: 5px;
    left: 59px !important;
}
.form-outline .form-control:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.form-outline .form-control~.form-notch div { border: 0;  }
.customodal .modal-header {border-bottom: 0;padding: 0;}
.customodal .modal-footer {border-top: 0;align-items: center;justify-content: center;padding: 0;}
.customodal .modal-footer button { background: #0E355B !important; }



/* Services */
.ah_services figure {
    padding: 0px 20px;
    color: white;
}

.ah_services figure figcaption {
    margin: 20px 0;
    font-size: 12px;
    font-weight: 300 !important;
}

.ah_services h5 {
    font-size: 16px;
    font-weight: 600;
}

.ah_services figure span {
    display: flex;
    min-height: 90px;
    align-items: center;
    justify-content: center;
}
/* WCU Sec */
ul.img-ul {
    padding: 0;
    margin: 0;
}

ul.img-ul li {
    position: relative;
    display: flex;
    /* flex-wrap: wrap; */
    gap: 0px 20px;
}

ul.img-ul li figure {
    max-width: 50px;
    flex: 0 0 auto;
    width: 50px !important;
    height: 50px !important;
    background-color: #6C7890;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.img-ul li h5 {
    font-size: 17px;
    font-weight: 600;
    color: #0E355B;
    margin-bottom: 10px;
}
/* Prot */

.bg-gray {
    background-color: #F9F6F6;
}

.our-work {
    padding: 40px 50px;
    margin-bottom: 40px;
}
.customodal .form-outline i.fa {
    margin-left: 11px;
    border-right: 1px solid #e1e1e1;
    padding-right: 8px;
}

.form-outline .form-control:focus + label {
    left: 22px !important;
    top: 5px;
    background-color: white;
    line-height: 1;
}
.ah_textbox {
    align-items: flex-start !important;
}

.ah_textbox i.fa {
    margin-top: 13px;
}
.ah_textbox textarea.active+label {color: blue !important;top: 0px !important;}

@media ( max-width: 1024px ) {
	.header ul li { margin: 0 0 0 20px; }
	.banner figure { min-width: 100% !important; max-width: 100% !important; }
	ul.img-ul li figure{min-width: auto !important;max-width: auto !important;margin-top: 0;}
	.banner .box ul li { width: 42%; }
}


@media ( max-width: 812px ) { 
	.header ul li { margin: 0 0 0 7px; }
	.header ul li a { font-size: 14px; }
	.header ul li:last-child a { padding: 10px; }
	.banner { padding: 7% 0; }
	.banner .box h4 { font-size: 18px; margin: 0 0 20px; line-height: 25px; }
	.banner .box p { line-height: 23px; margin: 0 0 20px; }
	.banner .box ul li { width: 100%; margin: 10px 0 0;  }
	.sevice-sec .serv-box { padding: 30px 20px; }
	.sevice-sec .serv-box span,
	.sevice-sec .serv-box h5 { margin: 0 0 15px; font-size: 16px; }
	.sevice-sec .serv-box p { line-height: 21px; }	

	.home-techno h4 { font-size: 20px; margin: 0 auto 20px; line-height: 31px; width: 100%; }
	.home-techno p { line-height: 23px; }

	.we-ofer figure { padding: 20px; }
	.we-ofer figure span img { max-height: 45px; }
	.we-ofer figure figcaption { font-size: 14px; }

	.footer .foot-top { padding: 20px 0;  }
	.footer .foot-top h4 { font-size: 16px; letter-spacing: 0; }
	.footer .foot-mid figure a.foot-logo img { max-height: 80px; }
	.footer .foot-mid h4 { font-size: 14px; }
	.about-banner{
		margin-top: 20px !important; padding: 0% 0 !important;
	}
}

@media ( max-width: 736px ) { 
	body *{
		text-align: center;
	}
	.header a.logo { margin: 0 0 20px; display: block; text-align: center; }
	.header ul { text-align: center; }

	.banner .box ul li a img { width: 4%; }
	.banner figure { margin: 10% 0 0; }
    .our-work figure {
        margin-bottom: 20px;
    }

	.sevice-sec { background: transparent !important; }
	.sevice-sec .serv-box { margin: 20px 0 0; }

	.we-ofer { padding: 70px 0 30px; }

	.footer .foot-top h4 { margin: 0 0 20px; }
	.footer .foot-mid, .footer .copyright { text-align: center; }
	.footer .foot-mid h4 { margin: 20px; }
	.footer .copyright p { padding: 3px 0; }

}

@media ( max-width: 568px ) { 
	.home-techno h4 { font-size: 18px; }
}


.chip {
   margin-top: -30px !important;
   margin-bottom: 10px !important;
   padding: 0px 5px !important;
}

/* Team Grid */

.team-section {
    padding: 60px 16px;
}

.team-section h3 {
    font-size: 26px;
    margin: 0 0 30px 8px;
    line-height: 33px;
}

.team-grid {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
}

.team-grid-item {
    flex: 0 0 calc(25% - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.team-grid-item h4 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}

.team-grid-item p {
    text-align: center;
}

.team-grid-img-wrap {
    position: relative;
    z-index: 10;
    border-radius: 18px;
    overflow: hidden;

}

.team-grid-item-overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    opacity: 0;
    transition: opacity .3s cubic-bezier(.645, .045, .355, 1);
}

.team-grid-item-overlay span{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.team-grid-img-wrap:hover .team-grid-item-overlay {
    opacity: 1;
    cursor: pointer;
}

.bio-dialog {
    padding: 30px;
    position: relative;
}

.bio-dialog-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 20px;
    z-index: 100;
}

.new-line-fix {
    white-space: pre-line;
}

@media screen and (max-width: 992px) {
    .team-grid {
        gap: 16px;
    }

    .team-grid-item {
        flex: 0 0 calc(33.333% - 24px);
    }
}

@media screen and (max-width: 624px) {
    .team-grid {
        gap: 16px;
    }

    .team-grid-item {
        flex: 0 0 calc(50% - 16px);
    }
}